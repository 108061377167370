import { apiBase, ApiResponse, customPost } from "./utils"

const baseUrl = `${apiBase}/api/cerereAudienta`

export type DataCerereAudienta = {
  accountId?: string
  isCasnic: boolean
  denumire: string
  cuicnp: string
  adresa: string
  localitate: string
  judet: string
  numeReprezentantLegal?: string
  email: string
  telefon: string
  subiect: string
  nuSePoateDeplasa: boolean
  comunicareTelefon: boolean
  acordDate: boolean
}

export function postCerereAudienta(
  formData: DataCerereAudienta
): Promise<ApiResponse<null>> {
  return customPost(baseUrl, formData) as Promise<ApiResponse<null>>
}
