import { navigate, PageProps } from "gatsby"
import React from "react"
import Button from "../../../components/Button"
import FormeErrorLine from "../../../components/CereriNew/FormErrorLine"
import NewTabs, { I_NewTabItem } from "../../../components/CereriNew/NewTabs"
import { casnicSvg, comercialSvg } from "../../../components/Dash/svgs"
import Footer from "../../../components/Footer"
import FormInput from "../../../components/FormInput"
import useScrollRestorationToTop from "../../../components/Hooks/useScrollRestorationToTop"
import { InfoMsgType } from "../../../components/InfoMsg"
import SiteMenu from "../../../components/siteMenu"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  CNP_ERROR,
  CNP_REGEX,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PHONENUMBER_ERROR,
  PHONENUMBER_REGEX,
} from "../../../utils/regex"
import { getUserInfo } from "../../../utils/services/keepAuth"

import NewCheckBox from "../../../components/CereriNew/CheckBox"
import CheckBoxAgreeToTerms from "../../../components/CereriNew/CheckBoxAgreeToTerms"
import NewTextArea from "../../../components/CereriNew/NewTextArea"
import PopupLayout from "../../../components/Popups/PopupLayout"
import {
  DataCerereAudienta,
  postCerereAudienta,
} from "../../../utils/api/cerereAudienta"
import { Helmet } from "react-helmet"

const initialFormData: DataCerereAudienta = {
  isCasnic: true,
  denumire: "",
  cuicnp: "",
  adresa: "",
  localitate: "",
  judet: "",
  email: "",
  telefon: "",
  subiect: "",
  nuSePoateDeplasa: false,
  comunicareTelefon: false,
  acordDate: false,
}

const cereriButtonText = "Trimite cererea"

const tipClientTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Casnic",
    icon: casnicSvg,
    iconClassName: "casnic",
  },
  {
    id: 2,
    title: "Comercial",
    icon: comercialSvg,
    iconClassName: "comercial",
  },
]

function getFormErrorMessageForStep() {
  return "Te rugăm să completezi toate câmpurile"
}

function isButtonEnabled(formData: DataCerereAudienta) {
  return (
    ALFA_NUMERIC_REGEX(formData.denumire) &&
    (formData.isCasnic
      ? CNP_REGEX(formData.cuicnp)
      : ALFA_NUMERIC_REGEX(formData.cuicnp)) &&
    ALFA_NUMERIC_REGEX(formData.adresa) &&
    ALFA_NUMERIC_REGEX(formData.localitate) &&
    ALFA_NUMERIC_REGEX(formData.judet) &&
    EMAIL_REGEX(formData.email) &&
    PHONENUMBER_REGEX(formData.telefon) &&
    ALFA_NUMERIC_REGEX(formData.subiect) &&
    formData.comunicareTelefon &&
    formData.acordDate
  )
}

export default function CerereAvizAmplasament({ location }: PageProps) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
    // status: "error",
  })
  const [formData, setFormData] =
    React.useState<DataCerereAudienta>(initialFormData)
  const [nrHover, setNrHover] = React.useState(0)

  const timeOutRefs = React.useRef<NodeJS.Timeout[]>([])

  React.useEffect(() => {
    // Page unmount cleanup function
    return () => {
      timeOutRefs.current.forEach(clearTimeout)
    }
  }, [])

  useScrollRestorationToTop("body", location.pathname)

  const handleFormDataChange = (value: string, name: string) => {
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    const root = document.getElementById("cerereAudienta")

    if (root != null)
      root.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      })
    setInfoMsg({
      isLoading: true,
    })

    try {
      const apiResponse = await postCerereAudienta({
        ...formData,

        numeReprezentantLegal: formData.numeReprezentantLegal,
        accountId: getUserInfo()?._id ?? undefined,
      })
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: true,
          status: "ok",
          msg: "Veți primi un email de confirmarea.  Te redirecționăm către pagina principală",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            navigate("/")
          }, 5000)
        )
        // setFormData(initialFormData)
      } else {
        setInfoMsg({
          isLoading: true,
          status: "error",
          msg: "Vă rugăm reîncercați. Acest pop-up va dispărea în câteva momente!",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            setInfoMsg({ isLoading: false })
          }, 3000)
        )
      }
    } catch (error) {
      console.warn(error)
      setInfoMsg({
        isLoading: true,
        status: "error",
        msg: "Vă rugăm reîncercați.Acest pop-up va dispărea în câteva momente!",
      })
      timeOutRefs.current.push(
        setTimeout(() => {
          setInfoMsg({ isLoading: false })
        }, 3000)
      )
    }
  }

  return (
    <div id="cerereAudienta" className="cererePage">
      <Helmet>
        <title>Cerere Audienta - Gazmir</title>
        <meta
          name="description"
          content="Depune o Cerere de Audienta pentru a intra in contact cu un operator pe Gazmir.ro. Completeaza motivul audientei iar noi va vom comunica data programarii."
        />
        <meta
          name="keywords"
          content="Audienta Gazmir,Gazmir Audienta,Audienta Gazmir miroslava"
        />
      </Helmet>
      <SiteMenu location={location} showPaymentRedirectLoading={false} />

      <form id="formAudienta" className="newCereriContainer">
        <h1 className="title">Cerere Audienta</h1>
        <div className="cerereStep1">
          <NewTabs
            containerClassName="tipClientContainer"
            title="Tip client"
            subTitle="Ești client casnic sau comercial?"
            items={tipClientTabItems}
            idTabSelected={formData.isCasnic ? 1 : 2}
            setIdTabSelected={(id: number) =>
              setFormData(oldData => ({ ...oldData, isCasnic: id === 1 }))
            }
          />
          <div className="formDate formDateIdentificare ncsShadow">
            <div className="formSectionTitle">
              {formData.isCasnic
                ? "Date personale titular (din C.I.)"
                : "Date de indetificare societate"}
            </div>
            <FormInput
              type="text"
              key={formData.isCasnic ? "CD" : "PD"}
              containerClassName="ncsInputContainer"
              label={
                formData.isCasnic ? "Nume și prenume" : "Denumire societate"
              }
              name="denumire"
              onChange={handleFormDataChange}
              value={formData.denumire}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            <FormInput
              type="text"
              key={formData.isCasnic ? "CC" : "PC"}
              containerClassName="ncsInputContainer"
              label={
                formData.isCasnic
                  ? "CNP - Cod Numeric Personal"
                  : "Certificat unic de înregistrare (C.U.I.)"
              }
              name="cuicnp"
              onChange={handleFormDataChange}
              value={formData.cuicnp}
              errorTest={formData.isCasnic ? CNP_REGEX : ALFA_NUMERIC_REGEX}
              errorMessage={formData.isCasnic ? CNP_ERROR : ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />

            <FormInput
              type="text"
              key={formData.isCasnic ? "CA" : "PA"}
              containerClassName="ncsInputContainer"
              label={
                formData.isCasnic ? "Adresă Domiciliu" : "Adresă sediu social"
              }
              name="adresa"
              onChange={handleFormDataChange}
              value={formData.adresa}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            <FormInput
              type="text"
              key={formData.isCasnic ? "CL" : "PL"}
              containerClassName="ncsInputContainer"
              label="Localitate"
              name="localitate"
              onChange={handleFormDataChange}
              value={formData.localitate}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            <FormInput
              type="text"
              key={formData.isCasnic ? "CJ" : "PJ"}
              containerClassName="ncsInputContainer"
              label="Județ"
              name="judet"
              onChange={handleFormDataChange}
              value={formData.judet}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            {!formData.isCasnic && (
              <FormInput
                type="text"
                key="PNR"
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Numele reprezentantului legal"
                name="numeReprezentantLegal"
                onChange={handleFormDataChange}
                value={formData.numeReprezentantLegal}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            )}
            <FormInput
              type="text"
              key={formData.isCasnic ? "CE" : "PE"}
              containerClassName="ncsInputContainer"
              label="Email"
              name="email"
              onChange={handleFormDataChange}
              value={formData.email}
              errorTest={EMAIL_REGEX}
              errorMessage={EMAIL_ERROR}
              showWithoutTouched={nrHover > 1}
            />
            <FormInput
              type="text"
              key={formData.isCasnic ? "CT" : "PT"}
              containerClassName="ncsInputContainer"
              label="Număr de telefon"
              name="telefon"
              onChange={handleFormDataChange}
              value={formData.telefon}
              errorTest={PHONENUMBER_REGEX}
              errorMessage={PHONENUMBER_ERROR}
              showWithoutTouched={nrHover > 1}
            />
          </div>
          <div className="formDate ncsShadow">
            <NewTextArea
              key="motiv"
              containerClassName="ncsInputContainer"
              labelTitle="Descrierea subiectului cererii de audiență"
              label="Ex.: Doresc să obțin o audiență în vederea renegocierii contractului."
              name="subiect"
              onChange={handleFormDataChange}
              value={formData.subiect}
              errorTest={ALFA_NUMERIC_REGEX}
              errorMessage={ALFA_NUMERIC_ERROR}
              maxLength={200}
              showWithoutTouched={nrHover > 1}
            />
          </div>

          <div className="formCheckBoxes">
            <NewCheckBox
              id="nuSePoateDeplasa"
              checked={formData.nuSePoateDeplasa}
              labelText="Fac parte din categoria persoanelor ce nu se pot deplasa și doresc o audiență telefonică."
              onChange={() => {
                setFormData(oldData => ({
                  ...oldData,
                  nuSePoateDeplasa: !oldData.nuSePoateDeplasa,
                }))
              }}
              showError={nrHover > 1 && !formData.nuSePoateDeplasa}
            />
            <NewCheckBox
              id="comunicareTelefon"
              checked={formData.comunicareTelefon}
              labelText="Sunt de acord ca data programării audienței să îmi fie comunicată telefonic, la numărul menționat, în termen de 5 zile de la data depunerii cererii."
              onChange={() => {
                setFormData(oldData => ({
                  ...oldData,
                  comunicareTelefon: !oldData.comunicareTelefon,
                }))
              }}
              showError={nrHover > 1 && !formData.comunicareTelefon}
            />
            <CheckBoxAgreeToTerms
              id="acordDate"
              checked={formData.acordDate}
              onChange={() =>
                setFormData(oldData => ({
                  ...oldData,
                  acordDate: !oldData.acordDate,
                }))
              }
              showError={nrHover > 1 && !formData.acordDate}
            />
          </div>
        </div>

        <FormeErrorLine
          status="error"
          errorMessage={getFormErrorMessageForStep()}
          doesFormHaveErrors={!isButtonEnabled(formData)}
          nrHover={nrHover}
          setNrHover={setNrHover}
        />
        {infoMsg.status !== "ok" && (
          <Button
            isLoading={infoMsg.isLoading}
            as={"button"}
            disabled={!isButtonEnabled(formData)}
            aspect="primary"
            className="ncsButton"
            label={cereriButtonText}
            onClick={handleSubmit}
          />
        )}
      </form>
      <PopupLayout
        querySelectorForBlurredBackground="#formAudienta"
        querySelectorForStopScrolling="#cerereAudienta"
        titleExtraClassName="popupExtraTitle"
        popupState={{
          showPopup: infoMsg.isLoading || infoMsg.status != null,
          type: "infoCerere",
          title:
            infoMsg.isLoading && infoMsg.status == null
              ? "Se trimite cererea"
              : infoMsg.status === "ok"
              ? "Cererea a fost trimisă cu succes"
              : "A apărut o eroare la transmiterea cererii",
          infoMsg: infoMsg,
        }}
      />
      <Footer />
    </div>
  )
}
